import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/common.scss';
// 优化搜索引擎问题
import { useTitle } from '@vueuse/core'
useTitle('Spindle Wave Home Textile Supplier')
const meta = document.createElement('meta');
meta.setAttribute('name', 'description');
meta.setAttribute('content', 'Spindle Wave Home Textile Supplier');
document.head.appendChild(meta);

createApp(App).use(store).use(router).mount('#app')
