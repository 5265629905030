import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  { path: '/:catchAll(.*)', redirect: '/root_page' },
  {
    path:'/goods_details',
    name:'goods_details',
    meta: {
      title: 'Spindle Wave Home Textile Supplier',
      description: 'Spindle Wave Home Textile Supplier',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/goods_details/index.vue')
  },{
    path: '/root_page',
    name: 'root_page',
    meta: {
      title: 'Spindle Wave Home Textile Supplier',
      description: 'Spindle Wave Home Textile Supplier',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/rootPage/index.vue'),
    redirect: '/home',
    children:[
      { 
        path: '/home', 
        name:'home',
        meta: {
          title: 'Spindle Wave Home Textile Supplier',
          description: 'Spindle Wave Home Textile Supplier',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue')
      },{
        path: '/blankets',
        name: 'blankets',
        component: () => import(/* webpackChunkName: "about" */ '../views/blankets/index.vue')
      },{
        path: '/blankets/faux',
        name: 'blankets_faux',
        component: () => import(/* webpackChunkName: "about" */ '../views/blankets/faux/index.vue')
      },{
        path: '/blankets/with',
        name: 'blankets_with',
        component: () => import(/* webpackChunkName: "about" */ '../views/blankets/with/index.vue')
      },{
        path: '/blankets/without',
        name: 'blankets_without',
        component: () => import(/* webpackChunkName: "about" */ '../views/blankets/without/index.vue')
      },{
        path: '/duvets',
        name: 'duvets',
        component: () => import(/* webpackChunkName: "about" */ '../views/duvets/index.vue')
      },{
        path: '/duvets/alternative',
        name: 'duvets_alternative',
        component: () => import(/* webpackChunkName: "about" */ '../views/duvets/alternative/index.vue')
      },{
        path: '/duvets/goose',
        name: 'duvets_goose',
        component: () => import(/* webpackChunkName: "about" */ '../views/duvets/goose/index.vue')
      },{
        path: '/duvets/quality',
        name: 'duvets_quality',
        component: () => import(/* webpackChunkName: "about" */ '../views/duvets/quality/index.vue')
      },{
        path: '/linen',
        name: 'linen',
        component: () => import(/* webpackChunkName: "about" */ '../views/linen/index.vue')
      },{
        path: '/linen/cotton',
        name: 'linen_cotton',
        component: () => import(/* webpackChunkName: "about" */ '../views/linen/cotton/index.vue')
      },{
        path: '/linen/tencel',
        name: 'linen_tencel',
        component: () => import(/* webpackChunkName: "about" */ '../views/linen/tencel/index.vue')
      },{
        path: '/linen/velvet',
        name: 'linen_velvet',
        component: () => import(/* webpackChunkName: "about" */ '../views/linen/velvet/index.vue')
      },{
        path: '/linen/luxurious_silk',
        name: 'luxurious_silk',
        component: () => import(/* webpackChunkName: "about" */ '../views/linen/luxurious_silk/index.vue')
      },{
        path: '/pillows',
        name: 'pillows',
        component: () => import(/* webpackChunkName: "about" */ '../views/pillows/index.vue')
      },{
        path: '/pillows/basic',
        name: 'pillows_basic',
        component: () => import(/* webpackChunkName: "about" */ '../views/pillows/basic/index.vue')
      },{
        path: '/pillows/design',
        name: 'pillows_design',
        component: () => import(/* webpackChunkName: "about" */ '../views/pillows/design/index.vue')
      },{
        path: '/protectors',
        name: 'protectors',
        component: () => import(/* webpackChunkName: "about" */ '../views/protectors/index.vue')
      },{
        path: '/protectors/mattress',
        name: 'protectors_mattress',
        component: () => import(/* webpackChunkName: "about" */ '../views/protectors/mattress/index.vue')
      },{
        path: '/protectors/pillow',
        name: 'protectors_pillow',
        component: () => import(/* webpackChunkName: "about" */ '../views/protectors/pillow/index.vue')
      },{
        path: '/towels_rugs',
        name: 'towels_rugs',
        component: () => import(/* webpackChunkName: "about" */ '../views/towels_rugs/index.vue')
      },{
        path: '/towels_rugs/rugs',
        name: 'towels_rugs_rugs',
        component: () => import(/* webpackChunkName: "about" */ '../views/towels_rugs/rugs/index.vue')
      },{
        path: '/towels_rugs/towels',
        name: 'towels_rugs_towels',
        component: () => import(/* webpackChunkName: "about" */ '../views/towels_rugs/towels/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
